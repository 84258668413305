/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  faLinkedin,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../atoms/Logo";
import Toast from "../../atoms/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleCopyEmail = () => {
    setShowToast(true);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <>
      <header className={style.Header}>
        <div className={style.Logo}>
          <Logo />
        </div>

        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={300}
          classNames="NavAnimation"
          unmountOnExit
        >
          <nav className={style.Nav}>
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/talks">Talks</Link>
            <Link to="/about">About</Link>
          </nav>
        </CSSTransition>

        <div className={style.Contact}>
          <a
            href="https://www.linkedin.com/in/vclementino"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>

          <a
            href="https://github.com/vagnerclementino"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>

          <a
            href="https://www.twitter.com/vclementino"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <CopyToClipboard
            text="vagner.clementino@gmail.com"
            onCopy={() => handleCopyEmail()}
          >
            <a
              href="mailto:vagner.clemetino@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.preventDefault()}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </CopyToClipboard>
        </div>

        <button onClick={toggleNav} className={style.Burger}>
          <FontAwesomeIcon color="whitesmoke" icon={faBars} />
        </button>
      </header>
      {console.log(`header showToast component value ${showToast}`)}
      <Toast
        message="Email copied with success!"
        backgroundColor="#5cb85c"
        showToast={showToast}
        onToastChange={setShowToast}
      />
    </>
  );
};

export default Header;
