import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Talks from "./pages/Talks";
import analytics from "./analytics"

const useGoogleAnalytics = () => {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    analytics.sendPageview(currentPath)
  }, [location])
};


const Routes = () => {
    useGoogleAnalytics();
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/blog" component={() => <Blog />} />
        <Route exact path="/about" component={About} />
        <Route exact path="/talks" component={Talks} />
      </Switch>
    );
};

export default Routes;
