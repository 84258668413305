import React, {useEffect} from 'react';

const SpeakerDeckEmbed = ({ deckId, startSlide=1, ratio=1.777777777777 }) => {
	useEffect(() => {
		if (!document.querySelector('script[src="//speakerdeck.com/assets/embed.js"]')) {
			const script = document.createElement("script");
			script.async = true;
			script.src = "//speakerdeck.com/assets/embed.js";
			script.id = "speakerdeck-script";
			document.body.appendChild(script);
		}
	}, []);
	
	return <div
		key={deckId}
		className="speakerdeck-embed"
		data-id={deckId}
		data-slide={startSlide}
		data-ratio={ratio}
		data-testid={`speakerdeck-embed-${deckId}`}
	></div>
};
export default SpeakerDeckEmbed;