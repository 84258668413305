import React from 'react';
import style from './style.module.css';
import Header from '../../components/molecules/Header';
import SpeakerDeckEmbed from '../../components/molecules/TalkDeck';

const Talks = () => {
  const talks = [
    {
      dataId: "9e20e590427d47b5bb17ed196496c1ed",
      slide: 1,
      ratio: 1.7
    },
    {
      dataId: "8da96907eaf94289893414f1b9baa863",
      slide: 2,
      ratio: 1.7
    }
  ];

  return (
    <div className={style.container}>
      <Header />
      <div className={style.content}>
        <div className={style.presentationsGrid}>
          {talks.map(talk => (
            <SpeakerDeckEmbed
              key={talk.dataId}
              deckId={talk.dataId}
              ratio={talk.ratio}
              startSlide={talk.slide} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Talks;